import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { RiSearchLine, RiDropFill }  from 'react-icons/ri';
import { WiCloudy, WiStrongWind }  from 'react-icons/wi';

import '../styles/global.css'
import '../styles/pages/Landing.css';

import textLogo from '../assets/images/textLogo.png';

import inicialFigure from '../assets/images/landing_svgs/inicial.svg';
import rainFigure from '../assets/images/landing_svgs/rain.svg';
import sunFigure from '../assets/images/landing_svgs/sun.svg';
import snowFigure from '../assets/images/landing_svgs/snow.svg';
import thunderFigure from '../assets/images/landing_svgs/thunder.svg';
import cloudyFigure from '../assets/images/landing_svgs/cloudy.svg';

import initialIcon from '../assets/images/icons/04.svg';

import dayBg from '../assets/images/Backgrounds/day_bg.jpg';
import nightBg from '../assets/images/Backgrounds/night_bg.jpg';
import snowyBg from '../assets/images/Backgrounds/snowy_day_bg.webp';
import thunderstormBg from '../assets/images/Backgrounds/thunderstorm_bg.jpg';
import hazeNightBg from '../assets/images/Backgrounds/haze_night_bg.jpg';
import hazeDayBg from '../assets/images/Backgrounds/haze_day_bg.jpg';
import cloudyDayBg from '../assets/images/Backgrounds/cloudy_day_bg.png';
import cloudyNightBg from '../assets/images/Backgrounds/cloudy_night_bg.jpg';
import rainyBg from '../assets/images/Backgrounds/rainy_bg.webp';



import api from '../services/api';
import search from '../services/search';
import times from '../services/times';
import { LocationContext } from '../contexts/LocationContext';
import { DebounceInput } from 'react-debounce-input';
import { MotionBox } from '../components/MotionBox';

interface WeatherData {
  coord: {
    lon: number,
    lat: number
  },
  weather: Array<{
    main: string,
    description: string,
    icon: string
  }>,
  main: {
    temp: number,
    feels_like: number,
    temp_min: number,
    temp_max: number,
    pressure: number,
    humidity: number
  },
  wind: {
    speed: number
  },
  clouds: {
    all: number
  },
  dt: number,
  sys: {
    country: string
  },
  timezone: number,
  name: string
}

interface PrayerTimeData {
  timings: {
    Fajr: string,
    Sunrise: string,
    Dhuhr: string,
    Asr: string,
    Maghrib: string,
    Isha: string,
  }
}

interface SearchData {
  list: Array<{
    name: string,
    coord: {
      lat: number,
      lon: number
    },
    sys: {
      country: string
    }
  }>
}

function Landing() {
  const initialData: WeatherData = {
    coord: {
      lon: 0,
      lat: 0
    },
    weather: [{
      main: 'initial',
      description: '-',
      icon: ''
    }],
    main: {
      temp: 0,
      feels_like: 0,
      temp_min: 0,
      temp_max: 0,
      pressure: 0,
      humidity: 0
    },
    wind: {
      speed: 0
    },
    clouds: {
      all: 0
    },
    dt: 0,
    sys: {
      country: '-'
    },
    timezone: 0,
    name: '-'
  }

  const PrayerData: PrayerTimeData = {
    timings: {
      Fajr: "00:00",
      Sunrise: "00:00",
      Dhuhr: "00:00",
      Asr: "00:00",
      Maghrib: "00:00",
      Isha: "00:00",
    }
  }

  const propCurrentTime = null

  const [city, setCity] = useState<string>()
  const [data, setData] = useState<WeatherData>(initialData)
  const [prayerTimes, setPrayerTimes] = useState<PrayerTimeData>(PrayerData)
  const [results, setResults] = useState<SearchData>()
  const [valid, setValid] = useState(true)
  const [currentTime, setCurrentTime] = useState<string>(() => propCurrentTime || new Date().toLocaleTimeString('en-US', { hour12: false }));
  const [highlightedPrayer, setHighlightedPrayer] = useState<string | null>(null);



  const {location, setLocation} = useContext(LocationContext);
  const apiKey = process.env.REACT_APP_API_KEY;

  const currentDate = new Date();
  let isActive
  const daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Extract the date, month, and year from the Date object
  const days = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
  const year = currentDate.getFullYear();
  const formattedDate = `${days < 10 ? '0' + days : days}-${month < 10 ? '0' + month : month}-${year}`;



const dayName = daysArray[currentDate.getDay()];
const dayLong = currentDate.getDate();
const monthName = months[currentDate.getMonth()];


const formattedDateLong = `${dayName}, ${monthName} ${dayLong}`;

function getOrdinal(n: number): string {
  if (n >= 11 && n <= 13) {
    return n + 'th';
  }

  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 10;
  return n + (suffixes[v] || 'th');
}


  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const newTime = new Date().toLocaleTimeString('en-US', { hour12: false });
  //     setCurrentTime(newTime);
  //   }, 3600000); // Update every minute

  //   // Clear interval on component unmount to prevent memory leaks
  //   return () => clearInterval(intervalId);
  // }, [setCurrentTime]);

  
  useEffect(() => {
    const prayerKeys = Object.keys(prayerTimes?.timings || {});
    const highlighted = prayerKeys.find((key, index, array) => {
      const previousTime = array[index - 1] ? prayerTimes?.timings[array[index - 1] as keyof typeof prayerTimes.timings] : '05:30';
      const nextTime = array[index + 1] ? prayerTimes?.timings[array[index + 1] as keyof typeof prayerTimes.timings] : '5:29';

      return currentTime >= previousTime && currentTime < nextTime;
    });

    if (highlighted) {
      setHighlightedPrayer(highlighted);
    }
  }, [currentTime, prayerTimes]);

  
  useEffect(() => {
    if(!location) {
      getLocation();
    } else {
      handleCityByCordinates(location.lat, location.lng);
    }
  }, []);

  function getLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        setLocation({
          lat: latitude,
          lng: longitude
        });

        handleCityByCordinates(latitude, longitude);
      });
    } else {
      alert('Browser does not support geolocation')
    }
  }

  let background = dayBg
  let day = true;

  switch (data?.weather[0].icon.slice(2)) {
    case 'n':
      day = false;
  }

  let LandingImg = inicialFigure

  switch (data?.weather[0].main) {
    case 'Clear':
      LandingImg = sunFigure;
      background = day ? dayBg : nightBg;
      break

    case 'Clouds':
      LandingImg = sunFigure;
      background = day ? cloudyDayBg : cloudyNightBg;
      break

    case 'Haze':
      LandingImg = cloudyFigure;
      background = day ? hazeDayBg : hazeNightBg;
      break

    case 'Thunderstorm':
      LandingImg = thunderFigure;
      background = thunderstormBg;
      break
      
    case 'Rain':
      LandingImg = rainFigure;
      background = rainyBg;
      break

    case 'Drizzle':
      LandingImg = rainFigure;
      background = rainyBg;
      break

    case 'Snow':
      LandingImg = snowFigure;
      background = snowyBg;
      break

    default:
      LandingImg = inicialFigure
      break
  }

  const icons = require.context( '../assets/images/icons', true, /\.(png|jpe?g|svg)$/);
  const paths = icons.keys ()
  const images = paths.map( path => icons ( path ) )

  let icon = initialIcon
  
  switch (data?.weather[0].icon) {
    case '01d':
      icon = images[0]
      break

    case '01n':
      icon = images[1]
      break

    case '02d':
      icon = images[2]
      break

    case '02n':
      icon = images[3]
      break

    case '03d' || '03n' || '04d' || '04n':
      icon = images[4]
      break

    case '09d':
      icon = images[5]
      break

    case '09n':
      icon = images[6]
      break

    case '10d' || '10n':
      icon = images[7]
      break

    case '11d':
      icon = images[8]
      break
    
    case '11n':
      icon = images[9]
      break

    case '13d':
      icon = images[10]
      break

    case '13n':
      icon = images[11]
      break

    case '50d':
      icon = images[12]
      break

    case '50n':
      icon = images[13]
      break
  }

  async function handleCity(e: FormEvent) {
    e.preventDefault();
    await api.get(`?q=${city}&appid=${apiKey}&units=metric`).then(response => {
      setData(response.data)      
    }).catch(() => {setValid(false)})
   

   await times.get(`${formattedDate}?latitude=${data?.coord.lat}&longitude=${data?.coord.lon}&method=2`).then(response => {
    const transformedPrayerTimes = transformPrayerTimeData(response.data);
    setPrayerTimes(transformedPrayerTimes);        
    }).catch(() => {setValid(false)})
    
    if (data.name !== '-') {
      setValid(true)
    }    
  }

  async function handleCityByCordinates (lat: number, lon: number) {
    await api.get(`?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric`).then(response => {      
      setValid(true)
      setData(response.data)      
    })

    await times.get(`${formattedDate}?latitude=${lat}&longitude=${lon}&method=2`).then(response => {
      const transformedPrayerTimes = transformPrayerTimeData(response.data);
      setPrayerTimes(transformedPrayerTimes);
    });
    
  }

  function transformPrayerTimeData(apiData: any): PrayerTimeData {
    return {
        timings: {
          Fajr: apiData.data.timings.Fajr,
          Sunrise: apiData.data.timings.Sunrise,
          Dhuhr: apiData.data.timings.Dhuhr,
          Asr: apiData.data.timings.Asr,
          Maghrib: apiData.data.timings.Maghrib,
          Isha: apiData.data.timings.Isha,
        }
    };
  }

  function convertTo12HourFormat(time: string): string {
    const [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert hour to 12-hour format
  
    return `${hour12}:${minute < 10 ? '0' + minute : minute} ${period}`;
  }

  // async function handleChangeValue(value: string) {
  //   try {
  //     setCity(value)
  //     const query = `?q=${value.trim()}&type=like&sort=population&cnt=30&appid=${apiKey}`;

  //     await search.get(query).then(response => {
  //       setResults(response.data)
  //     });
  //     setValid(true);
  //   } catch(err) {
  //     setValid(false);
  //     console.error(err);
  //   }
  // }
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setCity(value);
  };

  function capitalizeString(string: string) {
    return string[0].toUpperCase() + string.slice(1)
  }

  return (
    <div id="main" >
      <div className="background">
        <img src={background} alt="Wallpaper" className="img-background"/>
      </div>
      <div className="main-grid">
   
      <div className="app-name">
      <span className='main-text'>Prayer & Weather</span>
      </div>
      <MotionBox>
          <div className="content">
          <div className="principal">
            <div className="header">
              <form onSubmit={handleCity}>
                <div className="extras">
                  <div className="get-location" onClick={getLocation}>
                    <span className="get-location-button">
                      My location
                    </span>
                  </div>
                  {!valid && (
                    <span className="snackbar">invalid city</span>
                  )}
                </div>
                
                <div className="input-wrapper">
                <input
                  placeholder="Enter your city"
                  type="text"
                  name="city"
                  value={city}
                  onChange={handleChange}
                  className="cityInput"
                  autoComplete="off"
                />

                  <div className="search-results">
                    {results && results.list.map(result => {
                      const country = result.sys.country
                      const flag = `https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/${country.toLowerCase()}.png`;

                      return (
                        <div key={result.coord.lat} className="result-item" onClick={() => handleCityByCordinates(result.coord.lat, result.coord.lon)}>
                          <img className="result-flag" src={flag} alt="bandeira"/>
                          <p><span className="result-city">{result.name}</span>, {country}</p>
                        </div>
                      )
                    })}

                  </div>

                  <button type="submit" className="searchButton">
                    <RiSearchLine />
                  </button>
                </div>
              </form>

              
            </div>
            <div className="result">
              <img
                src={icon}
                alt="Climate"
                className="weather-icon"
              />
              <h1 className="temperature">
                {data?.main.temp.toFixed(0)}<span>ºC</span>
              </h1>

              <span className="description">{capitalizeString(String(data?.weather[0].description))}</span>

              <span className="local">
                {`${data?.name}, ${data?.sys.country}`}&nbsp;&nbsp;
                {data?.sys.country !== '-' && <img src={`https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/${data?.sys.country.toLowerCase()}.png`} alt="country"/>}
              </span>
            </div>

            <div className="other-results">
              <div className="other">
                Thermal sensation: <br/>
                <span>{data?.main.feels_like.toFixed(1)} ºC</span>
              </div>
              <div className="other">
                Temp. Minimum: <br/>
                <span>{data?.main.temp_min.toFixed(1)} ºC</span>
              </div>
              <div className="other">
                Maximum temperature: <br/>
                <span>{data?.main.temp_max.toFixed(1)} ºC</span>
              </div>
            </div>
          </div>

          <div className="seconda">
            <div>
              <span className="highlighted-text">{highlightedPrayer}</span>
              <span className="highlighted-text-sub">{formattedDateLong}</span>

              
            </div>
          <div className='prayer-times-container'>
        {Object.keys(prayerTimes?.timings || {}).map((key) => (
          <div key={key} className={key === highlightedPrayer ? 'highlighted' : 'border'}>
            <span>
            {key}
            </span>
            <span>
              {convertTo12HourFormat(prayerTimes?.timings[key as keyof typeof prayerTimes.timings] || '')}
            </span>
            
          </div>
        ))}
      </div>
          </div>
        </div> {/*content*/}
      </MotionBox>
      <div className="credits">
        For&nbsp;
        <a href="https://dahiryusuf.ca" target="_blank" rel="noopener noreferrer">
          <strong>Hoyo</strong>
          </a>
      </div>
      </div>
     
    </div> // main
  )
}

export default Landing;
